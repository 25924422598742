import { store } from "../../../redux/store";
import { loginURL } from "apiManager/APIConfig";
import { CallAPI } from "../../../apiManager/ApiCall";
import { setAuth } from "../../../redux/slices/authSlice";
import { userURL } from "apiManager/APIConfig";
import { setAllUsers } from "../../../redux/slices/userSlice";

export const LoginCall = async (body) => {
  try {
    const response = await CallAPI({ url: loginURL, method: "POST", body });
    if (response?.access_token && response.role === "admin") {
      store.dispatch(setAuth(response));
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return { status: 400 };
  }
};

export const GetAllUsersCall = async (params) => {
  try {
    const { limit, page } = params;

    const response = await CallAPI({ url: userURL, method: "GET" });
    if (response) {
      console.log("pippo response all user", response);
      store.dispatch(setAllUsers(response));
    } else {
      return response;
    }
  } catch (error) {
    return { status: 400 };
  }
};
