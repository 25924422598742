import { basicURL } from "./APIConfig";
import { store } from "../redux/store";

export const CallAPI = ({
  method = "GET",
  url,
  body,
  formData = null,
  token,
  contentType = "application/json",
  setProgress = () => {},
}) => {
  return new Promise((resolve, reject) => {
    const access_token = token ?? store.getState().auth?.access_token;
    let bodyData = null;

    if (body) {
      bodyData = JSON.stringify(body);
    }
    if (formData) {
      bodyData = formData;
    }

    const xhr = new XMLHttpRequest();
    xhr.open(method, `${basicURL}${url}`, true);

    // Set up headers
    xhr.setRequestHeader("Authorization", `Bearer ${access_token}`);
    xhr.setRequestHeader("Content-Type", contentType);

    // Track upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = event.loaded / event.total;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      console.log("onload event fired with status:", xhr.status);
      const responseContentType = xhr.getResponseHeader("Content-Type");
      let data = xhr.responseText;

      // Try parsing JSON response if content type is JSON
      if (responseContentType?.includes("application/json")) {
        try {
          data = JSON.parse(xhr.responseText);
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
        }
      }

      // Resolve for success status
      if (xhr.status >= 200 && xhr.status < 300) {
        setProgress(0);
        resolve(data);
      } else {
        console.log("Request rejected with status:", xhr.status);
        resolve(data);
      }
    };

    // Handle network error
    xhr.onerror = () => {
      resolve({
        status: xhr.status || 0,
        statusText: xhr.statusText || "Network error occurred",
        data: null,
      });
    };

    // Send the request
    try {
      xhr.send(bodyData);
    } catch (sendError) {
      console.error("Error sending request:", sendError);
      reject({
        status: 0,
        statusText: "Failed to send request",
        data: null,
      });
    }
  });
};
