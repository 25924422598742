import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  all: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.all = action.payload;
      if (isEmpty(state.selected)) {
        state.selected = action?.payload?.[0];
      }
      return state;
    },
    setSelectedUser: (state, action) => {
      state.selected = action.payload;
      return state;
    },
  },
});

const userReducer = userSlice.reducer;

export const { setAllUsers, setUsersByFolder, setSelectedUser } = userSlice.actions;

export default userReducer;
