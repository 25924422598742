import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "dark",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
      return state;
    },
  },
});

const generalReducer = generalSlice.reducer;

export const { setTheme } = generalSlice.actions;

export default generalReducer;
