import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetAuth: () => {
      return {};
    },
  },
});

const authReducer = authSlice.reducer;

export const { setAuth, resetAuth } = authSlice.actions;

export default authReducer;
