/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDSnackbar from "components/MDSnackbar";
import { LoginCall } from "../../../redux/api/auth";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const onClickLogin = async () => {
    const body = {
      password,
      email: email?.toLowerCase(),
    };
    try {
      const response = await LoginCall(body);
      console.log("pippo response", response);

      if (response?.status == 400 || response?.status == 404) {
        setErrorSB(true);
      } else {
        if (response?.role === "admin") {
          window.location.reload();
        } else {
          setErrorSB(true);
        }
      }
    } catch (error) {
      console.log("pippo error", error);
    }
    //
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value); // Update email state
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value); // Update password state
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -60%)", // Center horizontally and vertically
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../../../assets/images/logos/appoka.png")}
            style={{ height: 70, width: 70, borderRadius: 10 }}
          />
        </div>
        <MDBox mb={2} />
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>Log Into Appoka</MDBox>
            <MDBox mb={2}>
              <MDInput onChange={onChangeEmail} type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={onChangePassword} // Capture email input changes
                type="password"
                label="Password"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography> */}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton onClick={onClickLogin} variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Auth Error"
              content="Wrong credentials."
              open={errorSB}
              onClose={closeErrorSB}
              close={closeErrorSB}
              bgWhite
            />
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
